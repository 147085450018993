import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Obrońcy sprawiedliwości
			</title>
			<meta name={"description"} content={"W Advocates for Justice nie jesteś tylko klientem – jesteś częścią naszego zaangażowania na rzecz przestrzegania sprawiedliwości i uczciwości w procesie prawnym."} />
			<meta property={"og:title"} content={"Obrońcy sprawiedliwości"} />
			<meta property={"og:description"} content={"W Advocates for Justice nie jesteś tylko klientem – jesteś częścią naszego zaangażowania na rzecz przestrzegania sprawiedliwości i uczciwości w procesie prawnym."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				info@ambrosiaglow.com
			</Override>
		</Components.Header>
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/2-1.jpg?v=2024-06-13T13:46:51.691Z) center"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Odwiedź nas
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Twarda 18, 00-105 Warszawa, Poland
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Wyślij do nas e-mail
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:info@ambrosiaglow.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									info@ambrosiaglow.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Zadzwoń do nas
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+48222918551
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					align-items="stretch"
					flex-direction="column"
					justify-content="center"
					text-align="right"
				>
					<Text margin="0px 0px 0px 0px" font="--headline2">
						Gotowy do obrony swoich praw?
					</Text>
					<Text margin="3rem 0px 0px 0px" font="--base">
						Wybierz Advocates for Justice i poznaj reprezentację prawną w najlepszym wydaniu. Skontaktuj się z nami już dziś, aby rozpocząć swoją podróż do sprawiedliwości. Twoje prawa zasługują na najlepszą obronę.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header>
			<Override slot="link">
				+48222918551
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});